<template>
  <GtLink
    v-if="!(data.content.link && data.content.link.includes('/login'))"
    :to="data.content.link"
    class="sp-landing-buttonblock button-block"
    :style="{ width: style.width }"
    target="_blank"
    :click_content="data.content.text"
    :destination_url="data.content.link"
    click_type="按钮"
    :module_name="data.operationData.module_name"
    :module_rank="data.operationData.module_rank"
    :operation_name="data.operationData.operation_name"
    :operation_id="data.operationData.operation_id"
    :operation_rank="data.operationData.operation_rank"
    expose_type="手动滑动"
  >
    <sp-button
      size="small"
      :class="[data.customizedClassName]"
      :style="{ width: style.width }"
      @click="handleClick"
    >
      {{ data.content.text }}
    </sp-button>
  </GtLink>
  <nuxt-link
    v-else
    :to="data.content.link || '#'"
    :module_name="data.operationData.module_name"
    :module_rank="data.operationData.module_rank"
    :operation_name="data.operationData.operation_name"
    :operation_id="data.operationData.operation_id"
    :operation_rank="data.operationData.operation_rank"
    :destination_url="data.content.link"
    click_type="图片"
    expose_type="手动滑动"
    class="operation-img-link sp-landing-buttonblock button-block"
    :class="{ 'operation-img-no-jump': !data.content.link }"
    :target="data.content.link && data.content.link.includes('/login') ? '' : '_blank'"
    :click_content="data.content.altText"
  >
    <sp-button
      size="small"
      :class="[data.customizedClassName]"
      :style="{ width: style.width }"
      @click="handleClick"
    >
      {{ data.content.text }}
    </sp-button>
  </nuxt-link>
</template>
<script>
import { GtLink, GtButton } from '@speedshop/template';
export default {
  components: {
    'sp-button': GtButton,
    GtLink
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  computed: {
    style() {
      return this.data && this.data.style;
    }
  },
  methods: {
    handleClick() {
      const {
        content: { clickEvent }
      } = this.data;
      const fn = clickEvent ? eval(clickEvent) : null;
      fn && fn();
    }
  }
};
</script>
<style lang="scss">
.sp-landing-buttonblock {
  max-width: 100%;
  .gt-button.el-button.el-button--small {
    border-radius: var(--landingpage-button-border-radius);
    background-color: var(--landingpage-button-bg-color);
    color: var(--landingpage-button-font-color);
    border: var(--landingpage-button-border);
    font-weight: var(--landingpage-button-font-weight);
    font-size: var(--landingpage-button-font-size);
    padding: var(--landingpage-button-inner-padding);
    &:hover {
      background-color: var(--landingpage-button-hover-bg-color);
      color: var(--landingpage-button-hover-font-color);
      border: var(--landingpage-button-hover-border);
    }
  }
}
</style>
<style lang="scss">
.page-layout_mobile {
  .sp-landing-buttonblock {
    .gt-button.el-button.el-button--small {
      font-size: var(--landingpage-mobile-button-font-size);
      padding: var(--landingpage-mobile-button-inner-padding);
      border-radius: var(--landingpage-mobile-button-border-radius);
    }
  }
}
</style>
