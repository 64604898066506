<template>
  <section
    class="sp-landing-textblock text-block"
    :style="{ justifyContent: data.style && data.style.justifyContent }"
  >
    <p v-if="isPlainText" v-html="filterText"></p>
    <div v-else ref="shadow"></div>
  </section>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    filterText() {
      const { content: { textType, plainText, text = '' } = {} } = this.data || {};
      const result = textType === 'plainText' ? plainText : text;
      // .replace(/<style>[\s\S]*?<\/style>/g, '');

      return result;
    },
    isPlainText() {
      const { content: { textType } = {} } = this.data || {};
      return textType === 'plainText';
    }
  },
  mounted() {
    const { content: { textType } = {} } = this.data || {};
    if (textType !== 'plainText') {
      this.shadowContent();
    }
  },
  methods: {
    shadowContent() {
      const ifm = this.$refs.shadow;
      if (!ifm) return;
      if (this.shadow) {
        const para = document.createElement('shadow');
        para.innerHTML = this.filterText + '<style>*{  }</style>';
        this.shadow?.appendChild(para);
      } else {
        const shadow = ifm.attachShadow({ mode: 'open' });
        this.shadow = shadow;
        const para = document.createElement('shadow');
        para.innerHTML = this.filterText + '<style>*{  }</style>';
        shadow?.appendChild(para);
      }
    }
  }
};
</script>
