<template>
  <LandingPage
    v-if="columnList.length"
    :column-list="columnList"
    class="sp-landing-page"
  ></LandingPage>
  <NotFoundPage v-else></NotFoundPage>
</template>

<script>
import { useLandingPage, useBasic } from '@speedshop/sdk-api';
import { pageMixinCommon, GtMessage } from '@speedshop/template';
import LandingPage from '@/components/LandingPage/index.vue';
import NotFoundPage from '@/pages/notFound.vue';
import { checkFFSQualification } from '@/graphql/lp.gql';

export default {
  components: {
    LandingPage,
    NotFoundPage
  },
  mixins: [pageMixinCommon],
  beforeRouteLeave(to, from, next) {
    if (this.isLogin && !this.isGuest && to.path.includes('login')) {
      //
      GtMessage.warning('您已注册会员并登录');
      next(false);
    } else if (to.path.includes('login')) {
      this.$eventBus.$emit('LoginShow', { showLogin: true, showGuest: false });
      next(false);
    } else {
      next();
    }
  },
  scrollToTop: true,
  async asyncData({ route, store, res }) {
    const { path, query } = route;

    const LandingPage = useLandingPage();
    try {
      const { landingData, seoInfo } = await LandingPage.init({
        pageUrl: path === '/' || !path ? '/page/home' : path,
        deviceType: store.getters.isMobile === true ? 'h5' : 'pc',
        preview: query.preview === 'true',
        cacheFlag: query.preview !== 'true'
      });
      if (!landingData || !landingData.length) {
        res && (res.statusCode = 404);
      }

      return {
        columnList: landingData,
        seoInfo
      };
    } catch (e) {
      console.log(e, 'lp error');
      res && (res.statusCode = 404);
      return {
        columnList: [],
        seoInfo: {}
      };
    }
  },

  data() {
    return {
      seoInfo: {},
      columnList: [],
      loading: true
    };
  },
  head() {
    const { seoTitle = '', seoDesc = '', seoKeyword = '' } = this.seoInfo || {};
    const head = { meta: [] };
    if (seoTitle) {
      head.title = seoTitle;
    }
    if (seoDesc) {
      head.meta.push({
        hid: 'description',
        name: 'description',
        content: seoDesc
      });
    }
    if (seoKeyword) {
      head.meta.push({
        hid: 'keywords',
        name: 'keywords',
        content: seoKeyword
      });
    }
    return head;
  },
  watch: {
    // async isMobile() {
    //   await this.init();
    // },
    // async $route(to, from) {
    //   await this.init();
    // }
  },
  async mounted() {
    this.seoInfo.seoTitle && (this.$route.meta.title = this.seoInfo.seoTitle);
    this.$nextTick(() => {
      this.loading = false;
    });
    await this.getFfsShow();
    this.$eventBus.$on('drawLoginSuccessCallback', async () => {
      await this.getFfsShow();
    });
  },
  methods: {
    async getFfsShow() {
      const basic = useBasic();
      let params = {};
      const ffsActivityPath = localStorage.getItem('ffsActivityPath');
      if (this.$route.path === ffsActivityPath) {
        params = {
          checkDto: {
            venuePath: this.$route.path
          }
        };
      } else {
        params = {
          checkDto: {
            venuePath: ''
          }
        };
      }
      const data = await basic.gqlClient.query(checkFFSQualification, params);
      localStorage.setItem('showFFSPrice', data?.data?.data?.success);
    },
    async init() {
      this.loading = true;
      const { path, query } = this.$route;

      const LandingPage = useLandingPage();
      const { landingData, seoInfo } = await LandingPage.init({
        pageUrl: path === '/' || !path ? '/page/home' : path,
        deviceType: this.isMobile ? 'h5' : 'pc',
        preview: query.preview === 'true'
      });
      if (landingData) {
        console.log('%c [  ]-124', 'font-size:13px; background:pink; color:#bf2c9f;', landingData);
        this.columnList =
          (landingData &&
            landingData.length > 0 &&
            landingData.filter(item => item.type === 'layout' && item?.style?.status !== 0)) ||
          [];
        this.seoInfo = seoInfo;

        console.log(
          '%c [  ]-132',
          'font-size:13px; background:pink; color:#bf2c9f;',
          this.columnList
        );
      }
      this.$nextTick(() => {
        this.loading = false;
      });
    }
  }
};
</script>
